

import animate from './global/animate';

const selectors = {
	item : '.m-hover-grid__item',
	title : '.m-hover-grid__title',
	firstTitle : '.m-hover-grid__title--first'
};


function findIndex(needle, haystack = []) {
	let index = null;
	const found = haystack.filter((item, i) => {
		if (needle === item) {
			index = i;
			return true;
		}
		return false;
	});
	return found ? index : null;
}


export const initInstance = function(el) {

	let current;
	const items = [...el.querySelectorAll(selectors.item)];
	const firstTitle = el.querySelector(selectors.firstTitle);
	const titles = [...el.querySelectorAll(selectors.title)].filter(function(item) {
		if (firstTitle === item) return false;
		return item;
	});

	function findTitleFromItem(item) {
		const ind = findIndex(item, items);
		return titles[ind] ? titles[ind] : null;
	}

	function onmouseoverHandler(event) {
		event.stopPropagation();
		const oldItem = current;
		if (current === event.currentTarget) return false; // don't repeat
		current = event.currentTarget;
		const oldTitle = findTitleFromItem(oldItem) || firstTitle;
		changeTitle(findTitleFromItem(current), oldTitle);
	}

	function onmouseleaveHandler(event) {
		event.stopPropagation();
		const oldItem = current;
		const newHoverIsItem = findIndex(event.relatedTarget, items);
		// has left area
		if (!newHoverIsItem) {
			current = null;
			changeTitle(firstTitle, findTitleFromItem(oldItem));
		}
	}

	function changeTitle(newItem, oldItem) {
		newItem.classList.add('m-hover-grid__title--in');
		animate.reflow(newItem);
		newItem.classList.add('m-hover-grid__title--current');
		animate.reflow(newItem);
		newItem.classList.remove('m-hover-grid__title--in');
		animate.removeClass(oldItem, 'm-hover-grid__title--current');
	}

	items.forEach(function(item, i) {
		item.addEventListener('mouseover', onmouseoverHandler);
		item.addEventListener('mouseleave', onmouseleaveHandler);
	});
}
